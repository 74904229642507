import * as React from 'react';
import useTheme from '@lobox/uikit/utils/useTheme';

function SvgCelebrateBirthday(props: any) {
  const { isDark } = useTheme();

  return isDark ? (
    <svg
      width={344}
      height={223}
      viewBox="0 0 344 223"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M69.5 223s-39.738-19.236-38.813-53.271c.924-34.035 38.01-39.359 63.384-18.375 25.477 20.985 38.52 10.719 51.669-7.029 13.15-17.749 33.39-57.908 70.27-42.875 27.532 11.171 45.92 41.135 51.878 68.175 4.11 18.583-1.849 38.211-16.128 50.426-.514.417-.76 1.95-1.76 2.949H69.5z"
        fill="url(#celebrateBirthDay0_linear)"
      />
      <path
        d="M149.647 35.419c-.515.097-.966-.032-1.408-.304-.448-.307-.903-.647-1.172-1.13-.262-.448-.427-.95-.387-1.49.049-.683.375-1.028 1.021-1.185.646-.156 1.212.021 1.659.506.534.574.904 1.215 1.183 1.944.073.2.112.405.116.617.002.39-.152.703-.551.849-.09.088-.296.127-.461.193z"
        fill="#5384EE"
        fillOpacity={0.2}
      />
      <path
        d="M171.872 88.582c-.514.097-.965-.031-1.407-.304-.449-.306-.904-.647-1.172-1.13-.262-.447-.428-.949-.387-1.489.049-.684.375-1.029 1.02-1.185.646-.157 1.213.02 1.659.505.535.575.904 1.216 1.183 1.944.074.2.112.405.117.618.002.39-.152.703-.551.849-.09.087-.29.16-.462.192z"
        fill="#507BDD"
      />
      <path
        d="M38.34 113.209c-.516.097-.966-.032-1.409-.304-.448-.307-.903-.647-1.171-1.13-.262-.447-.428-.949-.387-1.489.049-.684.374-1.029 1.02-1.186.646-.156 1.212.022 1.659.506.535.574.904 1.215 1.183 1.944.073.199.112.405.117.617.002.39-.152.703-.551.849-.09.088-.297.127-.462.193z"
        fill="#FF8C00"
      />
      <path
        d="M75.135 115.323c-.453.049-.871.092-1.288-.043-.45-.128-.783-.385-.908-.859a6.742 6.742 0 01-.22-1.166c-.055-.487.196-.853.657-1.046a3.34 3.34 0 01.77-.251c.624-.082 1.15.068 1.534.6.25.379.56.711.803 1.056.35.538.278.907-.26 1.257-.125.094-.29.16-.421.22-.227.114-.433.153-.667.232zM66.133 83.712c-.453.05-.871.092-1.288-.043-.45-.128-.783-.386-.908-.86a6.727 6.727 0 01-.22-1.165c-.055-.487.196-.854.657-1.047.262-.12.53-.205.77-.25.624-.082 1.15.068 1.535.6.249.379.56.711.803 1.056.35.538.277.907-.261 1.256-.125.095-.29.161-.421.221-.227.114-.433.152-.667.232zM130.632 50.169c.059-.438.16-.847.405-1.248.244-.401.589-.643 1.055-.624.397.032.822.023 1.191.096.478.088.755.427.846.907.051.274.069.555.011.815-.096.621-.403 1.07-1.036 1.294-.433.153-.826.333-1.266.45-.605.185-.959.003-1.144-.602l-.09-.48c.009-.143.038-.362.028-.608z"
        fill="#5384EE"
        fillOpacity={0.2}
      />
      <path
        d="M209.513 38.671c-.447.084-.809.045-1.176-.205a.67.67 0 01-.316-.545c.008-.712.085-1.436.456-2.11.315-.59.819-.934 1.491-.953.671-.02 1.086.293 1.174.951.09.48.078.98-.004 1.492-.072.369-.261.688-.578.89-.318.201-.635.403-1.047.48z"
        fill="#FF8C00"
      />
      <path
        d="M197.033 71.543c-.446.084-.809.045-1.176-.206a.667.667 0 01-.315-.544c.008-.712.085-1.436.456-2.11.315-.591.819-.934 1.49-.954.672-.019 1.086.294 1.175.952.09.48.077.98-.004 1.492-.073.369-.261.688-.579.89-.317.201-.669.41-1.047.48z"
        fill="#5384EE"
        fillOpacity={0.2}
      />
      <path
        d="M79.959 66.882c.178.57-.129 1.018-.633 1.362-.731.456-1.506.495-2.255.103a.908.908 0 01-.412-.491c-.224-.81-.01-1.56.512-2.19.437-.509 1.014-.653 1.67-.563.295.052.512.26.666.515.168.323.355.75.452 1.264zM205.419 26.162c.103.55.011.815-.431 1.11-.663.445-1.377.614-2.148.297-.382-.142-.489-.334-.451-.697.026-.43.051-.861.111-1.299-.006-.034-.013-.068.015-.109.079-.902.564-1.348 1.461-1.303.356.004.628.13.858.407.331.435.462.943.587 1.416-.022.075-.015.11-.002.178z"
        fill="#507BDD"
      />
      <path
        d="M82.22 97.237c-.2-.495-.18-.96.05-1.43.226-.504.485-1.014.914-1.378.395-.358.85-.585 1.371-.648.7-.06 1.094.15 1.36.81.26.626.192 1.207-.204 1.743-.453.618-1.034 1.117-1.703 1.527-.193.107-.393.18-.599.218-.405.112-.719-.042-.92-.36-.101-.158-.168-.323-.269-.482z"
        fill="#FF8C00"
      />
      <path
        d="M26.037 90.702c-.495.2-.79.148-1.182-.24-.57-.568-.836-1.228-.703-2.034.073-.37.219-.538.603-.575l1.29-.135.102-.02c.906-.098 1.408.305 1.576 1.196a.994.994 0 01-.257.9c-.348.421-.843.62-1.298.848-.028.04-.096.053-.13.06z"
        fill="#507BDD"
      />
      <path
        d="M39.16 61.231c2.01.866 7.092 2.186 7.327-1.48.123-1.799-1.899-2.911-3.411-1.882-2.3 1.567-.476 5.025 1.23 6.161 2.727 1.833 6.312.486 7.613-2.421.253-.545-.585-1.027-.831-.448-.724 1.627-2.245 2.8-4.06 2.785-1.96-.023-4.077-1.828-4.033-3.86.062-2.32 3.077-1.784 2.524-.19-.326.914-1.688 1.418-2.579 1.407-1.171.007-2.262-.499-3.305-.942-.573-.213-1.055.623-.476.87zM186.543 3.892c.874 2.002 3.666 6.45 6.325 3.963 1.33-1.244.635-3.421-1.163-3.723-2.748-.444-3.785 3.301-3.329 5.346.744 3.198 4.261 4.704 7.187 3.446.558-.247.246-1.147-.311-.9-1.646.699-3.568.49-4.875-.79-1.389-1.338-1.693-4.086-.259-5.527 1.643-1.657 3.464.842 1.969 1.584-.875.448-2.197-.156-2.841-.745-.861-.798-1.321-1.919-1.768-2.971-.281-.55-1.189-.274-.935.317z"
        fill="#5384EE"
        fillOpacity={0.2}
      />
      <path
        d="M93.227 50.477c1.647-1.445 3.588-3.407 2.773-5.846-.563-1.67-2.827-2.559-4.124-1.144-1.388 1.503.254 3.432 1.776 3.963 2.123.703 4.448-.159 5.79-1.902.37-.495-.406-1.025-.774-.53-.828 1.079-2.083 1.776-3.474 1.752-1.103-.006-4.257-1.474-2.269-2.805 1.795-1.224 2.612 1.605 2.2 2.818-.404 1.248-1.51 2.166-2.452 3.017-.539.35.097 1.082.554.677z"
        fill="#FF8C00"
      />
      <path
        d="M338.999 177.211c-1.2-4.794-7.5-4.794-11.1-1.898-1.1.899-2.1 2.098-2.9 3.197-.2-.4-.5-.7-1-.7l-4.2-.399c-.4 0-.8.2-1.1.499-.6-1.298-1.4-2.597-2.3-3.695-3-3.596-9.2-4.695-11.3-.2-2.1 4.495 1.8 6.892 3.1 7.99 1 .799 6.9.4 10 .2.1.5.5.999 1.1.999l4.2.4c.6.099 1.1-.3 1.2-.8 2.9.8 8.7 2.298 9.8 1.698 1.6-.899 5.8-2.497 4.5-7.291zm-28.3-1.198c1.7-1.099 5.4 1.897 7.7 3.795l-.2 1.798c-1.1-.3-2.7-.699-4-1.099-2.6-.699-5.9-2.896-3.5-4.494zm18.3 5.893c-1.4.099-3 .199-4.2.299l.2-1.798c2.6-1.498 6.8-3.695 8.3-2.397 2.1 2.098-1.6 3.696-4.3 3.896z"
        fill="url(#celebrateBirthDay1_linear)"
      />
      <path
        d="M294.199 189.696L297 223h43l2.499-31.905-32.1-5.294-16.2 3.895z"
        fill="url(#celebrateBirthDay2_linear)"
      />
      <path
        d="M294.199 189.696l16.2-3.895L310 223h-13.5l-2.301-33.304z"
        fill="url(#celebrateBirthDay3_linear)"
      />
      <path
        d="M294.199 189.696l16.2-3.895 32.1 5.294-.5 7.191-31.5-2.996-15.7 2.097-.6-7.691z"
        fill="url(#celebrateBirthDay4_linear)"
      />
      <path
        d="M343.4 194.99l-33.2-3.196-16.6 3.296-1-8.89 17.8-6.991 33.6 6.792-.6 8.989z"
        fill="url(#celebrateBirthDay5_linear)"
      />
      <path
        d="M310.2 191.794l-16.6 3.296-1-8.89 17.8-6.991-.1 12.685-.1-.1z"
        fill="url(#celebrateBirthDay6_linear)"
      />
      <path
        d="M329.8 183.004L328.5 223H323l1.6-41.094 5.2 1.098z"
        fill="url(#celebrateBirthDay7_linear)"
      />
      <path
        d="M258 200.084L259 223h45.5l.5-21.818-30.3-4.694-16.7 3.596z"
        fill="#5ED6CC"
      />
      <path
        d="M258 200.084L259.5 223h45l.5-21.818-30.3-4.694-16.7 3.596z"
        fill="url(#celebrateBirthDay8_linear)"
      />
      <path
        d="M258 200.084l16.7-3.596V223h-15.2l-1.5-22.916z"
        fill="url(#celebrateBirthDay9_linear)"
      />
      <path
        d="M258 200.084l16.7-3.596 30.3 4.694-.2 6.293-29.9-2.297-16.4 2.197-.5-7.291z"
        fill="url(#celebrateBirthDay10_linear)"
      />
      <path
        d="M305.9 204.578l-31.4-2.597-17.3 3.197-.9-8.49 18.4-6.093 31.4 6.292-.2 7.691z"
        fill="url(#celebrateBirthDay11_linear)"
      />
      <path
        d="M274.5 201.981l-17.3 3.197-.9-8.49 18.4-6.093v11.386h-.2z"
        fill="url(#celebrateBirthDay12_linear)"
      />
      <path
        d="M293.3 194.191V223h-4.8v-29.808l4.8.999z"
        fill="url(#celebrateBirthDay13_linear)"
      />
      <path
        d="M251.3 175.013c-2.3-4.794-8.8-3.396-12 .5-.9 1.198-1.7 2.697-2.3 3.995-.3-.399-.7-.599-1.2-.499l-4.5.499c-.5.1-.9.4-1 .799-.9-1.198-2-2.497-3.2-3.396-4-3.096-10.7-2.896-12 2.297-1.3 5.294 3.3 6.892 4.9 7.791 1.2.699 7.4-1.099 10.6-2.097.2.599.7.899 1.4.899l4.6-.6c.6-.1 1.1-.599 1.1-1.198 3.3.199 9.7.499 10.7-.5 1.2-.998 5.2-3.695 2.9-8.49zm-29.9 5.194c1.5-1.498 6.2.699 8.9 2.298l.2 1.897c-1.3 0-3-.1-4.5-.2-2.9-.099-6.9-1.797-4.6-3.995zm20.5 2.098c-1.4.499-3.1.899-4.3 1.298l-.2-1.898c2.3-2.197 6.3-5.493 8.1-4.394 2.6 1.598-.8 4.095-3.6 4.994z"
        fill="url(#celebrateBirthDay14_linear)"
      />
      <path
        d="M267.2 194.091l-1.7 28.908h-58.4l-.4-26.211 39.1-7.491 21.4 4.794z"
        fill="url(#celebrateBirthDay15_linear)"
      />
      <path
        d="M267.4 193.434L246 188.64v34.359h19.5l1.9-29.565z"
        fill="url(#celebrateBirthDay16_linear)"
      />
      <path
        d="M267.2 194.091l-21.4-4.794-39.1 7.491.2 9.189 38.9-4.195 21 2.797.4-10.488z"
        fill="url(#celebrateBirthDay17_linear)"
      />
      <path
        d="M205.4 201.682l40.8-4.495 22.1 4.195.9-12.085-23.5-8.39-40.4 9.588.1 11.187z"
        fill="url(#celebrateBirthDay18_linear)"
      />
      <path
        d="M246.2 197.187l22.1 4.195.9-12.085-23.5-8.39.2 16.38.3-.1z"
        fill="url(#celebrateBirthDay19_linear)"
      />
      <path
        d="M222 187.014l.493 35.986H229v-37.955l-7 1.969z"
        fill="url(#celebrateBirthDay20_linear)"
      />
      <path
        d="M248.7 182.305s2.7 1.298 4.6 6.492c1.9 5.194 1.4 15.781 1.4 15.781l3.2-9.988 4.6 9.689s-1.3-15.482-7.3-22.573c0 0-4.2-2.897-6.5.599z"
        fill="url(#celebrateBirthDay21_linear)"
      />
      <path
        d="M221.6 186.6s-2.9-2.297-7.6-.599c-4.7 1.698-12 15.681-12 15.681l7.7-4.894s.1 8.19.7 7.99c.6-.199 4.9-12.185 7.2-14.682 2.4-2.597 4-3.496 4-3.496z"
        fill="url(#celebrateBirthDay22_linear)"
      />
      <path
        d="M52.2 176.954L51 223H5.5l-3.9-41.95 32.4-7.991 18.2 3.895z"
        fill="#5ED6CC"
      />
      <path
        d="M52 176.947L50.815 222.9l-14.822.1H5.556L2 181.034l32.016-7.975L52 176.947z"
        fill="url(#celebrateBirthDay23_linear)"
      />
      <path
        d="M52.2 176.954L34 173.059 36.5 223h15l.7-46.046z"
        fill="url(#celebrateBirthDay24_linear)"
      />
      <path
        d="M52.2 176.934L34 173.045l-32.4 7.977.7 8.376 32.1-4.786 17.6 2.094.2-9.772z"
        fill="url(#celebrateBirthDay25_linear)"
      />
      <path
        d="M.9 185.509l33.7-5.085 18.6 3.29.6-11.367-20.1-7.278L0 175.239l.9 10.27z"
        fill="url(#celebrateBirthDay26_linear)"
      />
      <path
        d="M34.6 180.424l18.6 3.29.6-11.367-20.1-7.278.7 15.355h.2z"
        fill="url(#celebrateBirthDay27_linear)"
      />
      <path
        d="M14 170.671L17.31 223H23l-3.724-53.936L14 170.671z"
        fill="url(#celebrateBirthDay28_linear)"
      />
      <path
        d="M62.2 179.352c-1.9-4.195-7.7-3.197-10.5.099-1.1 1.299-2 3.097-2.6 4.495-.2-.2-.4-.3-.7-.3l-3.3.3c-.3 0-.5.2-.7.4-.8-1.299-2-2.897-3.3-3.996-3.4-2.796-9.2-2.796-10.4 1.599-1.2 4.494 2.7 5.992 4.1 6.791 1.1.7 7.1-.898 9.6-1.598v.4c0 .499.5.899 1 .899l3.3-.3c.5 0 .9-.499.9-.999v-.399c2.7.299 8.8.799 9.8 0 1.1-.999 4.7-3.097 2.8-7.391zm-22.3 7.191c-2.5-.3-5.9-1.798-3.9-3.596 1.5-1.298 5.9 1.199 8.2 2.597l.1 1.399c-1.1-.1-2.9-.3-4.4-.4zm13.8-1.199c-1.5.4-3.2.8-4.3 1.099l-.1-1.398c2-1.798 6-4.994 7.7-3.896 2.3 1.499-.8 3.596-3.3 4.195z"
        fill="url(#celebrateBirthDay29_linear)"
      />
      <path
        d="M76 194.833l-2 28.166H29.5l-1.8-26.768 32.2-5.393 16.1 3.995z"
        fill="url(#celebrateBirthDay30_linear)"
      />
      <path
        d="M76 194.833l-16.1-3.995v32.161H74l2-28.166z"
        fill="url(#celebrateBirthDay31_linear)"
      />
      <path
        d="M76 194.833l-16.1-3.995-32.2 5.393.5 7.492 31.5-3.197 15.7 2.198.6-7.891z"
        fill="url(#celebrateBirthDay32_linear)"
      />
      <path
        d="M26.8 200.227l33.2-3.196 16.6 3.296 1-9.09-17.8-7.091-33.6 6.892.6 9.189z"
        fill="url(#celebrateBirthDay33_linear)"
      />
      <path
        d="M60 197.031l16.6 3.296 1-9.09-17.8-7.091.1 12.885h.1z"
        fill="url(#celebrateBirthDay34_linear)"
      />
      <path
        d="M40.4 188.041L41.5 223H47l-1.4-36.057-5.2 1.098z"
        fill="url(#celebrateBirthDay35_linear)"
      />
      <path
        d="M144.515 111.389l-1.323-26.132s2.314-1.537 4.793 0v26.132h-3.47z"
        fill="url(#celebrateBirthDay36_linear)"
      />
      <path
        d="M145.561 68.183s-11.073 18.282 0 18.282c11.074 0 0-18.282 0-18.282z"
        fill="url(#celebrateBirthDay37_linear)"
      />
      <path
        d="M145.561 73.509s-6.721 11.034 0 11.034c6.722 0 0-11.034 0-11.034z"
        fill="#D7E9FF"
      />
      <path
        d="M123.139 141.859l-5.344-26.352s27.931-11.639 58.563-2.471l-3.086 25.968c.055 0-22.367-3.788-50.133 2.855z"
        fill="url(#celebrateBirthDay38_linear)"
      />
      <path
        d="M127.436 130.879c6.886 1.153 5.179-15.811 5.179-15.811s4.187 15.427 8.208 13.725c4.022-1.702 4.959-16.196 4.959-16.196s4.021 16.58 10.357 16.031c6.115-.549 8.098-13.945 8.209-14.823v-.055.055c-.111.878-1.929 14.658 2.699 14.658 2.644 0 6.005-4.447 8.484-8.399l.827-6.918c-30.632-9.223-58.563 2.416-58.563 2.416l1.322 6.423c2.204 4.282 5.124 8.345 8.319 8.894z"
        fill="url(#celebrateBirthDay39_linear)"
      />
      <path
        d="M128.262 134.393c-5.124 0-9.806-12.737-10.302-14.274-.165-.384.055-.824.441-.988.385-.165.826.055.991.439 1.928 5.38 6.06 13.725 9.09 13.231 2.865-.439 3.692-10.651 3.361-17.733a.77.77 0 01.661-.768c.386-.055.771.219.826.604 1.984 8.125 5.344 16.744 7.438 16.744h.055c2.093-.22 3.801-10.102 4.187-19.105 0-.384.33-.659.661-.714.386-.055.716.22.826.549 1.818 7.027 5.95 18.227 9.366 18.282h.055c3.361 0 6.446-10.431 7.603-17.019.055-.439.496-.714.881-.604.441.055.716.494.606.878-1.157 6.588-1.597 16.196 1.047 16.964 2.644.769 6.776-7.082 8.87-12.901.165-.384.606-.604.991-.439.386.164.606.604.441.988-.936 2.58-5.784 15.262-10.743 13.835-2.424-.714-3.085-4.612-3.085-8.729-1.542 4.391-3.746 8.619-6.666 8.619h-.055c-4.187-.11-7.768-9.443-9.586-15.207-.551 6.368-1.928 15.811-5.289 16.14-3.085.329-5.895-6.643-7.603-12.297-.165 5.599-1.101 12.901-4.682 13.505-.055-.055-.221 0-.386 0z"
        fill="#FAFAFA"
      />
      <path
        d="M120.219 119.46c0 .878-.716 1.537-1.543 1.537-.826 0-1.542-.714-1.542-1.537 0-.879.716-1.538 1.542-1.538.827 0 1.543.714 1.543 1.538zM134.102 114.848c0 .879-.716 1.537-1.543 1.537a1.536 1.536 0 110-3.074c.827 0 1.543.659 1.543 1.537zM145.891 114.464a1.54 1.54 0 001.543-1.537 1.54 1.54 0 00-1.543-1.538 1.54 1.54 0 00-1.542 1.538c0 .849.69 1.537 1.542 1.537zM165.945 113.696c0 .879-.716 1.537-1.542 1.537a1.537 1.537 0 110-3.074c.881 0 1.542.714 1.542 1.537zM175.752 118.746a1.54 1.54 0 001.542-1.537 1.54 1.54 0 00-1.542-1.537 1.54 1.54 0 00-1.543 1.537 1.54 1.54 0 001.543 1.537z"
        fill="#FAFAFA"
      />
      <path
        d="M114.434 174.084l-7.658-24.924s26.61-19.27 80.6-10.705l-4.187 28.383s-39.832-2.306-68.755 7.246z"
        fill="url(#celebrateBirthDay40_linear)"
      />
      <path
        d="M116.528 158.877c9.365-1.153 7.106-13.176 7.106-13.176s4.573 12.188 12.451 9.333c7.823-2.854 7.823-14.987 7.823-14.987s1.047 13.231 10.743 11.968c9.641-1.263 9.862-12.902 9.862-12.902s.936 12.957 9.861 12.902c5.895-.055 9.917-4.831 11.955-8.125l1.047-5.435c-53.99-8.565-80.6 10.705-80.6 10.705l2.865 6.972c1.873 1.867 4.132 3.075 6.887 2.745z"
        fill="url(#celebrateBirthDay41_linear)"
      />
      <path
        d="M117.629 164.093c-6.115 0-10.247-11.364-10.412-11.858-.166-.384.055-.823.44-.988.386-.165.827.055.992.439 1.267 3.459 5.454 12.133 10.082 10.705 4.683-1.482 4.572-12.462 4.132-16.579a.736.736 0 01.606-.824c.385-.055.771.165.881.494 1.267 3.898 5.62 13.725 10.523 12.682 4.958-1.043 7.658-13.505 8.319-18.226a.77.77 0 01.771-.659.77.77 0 01.771.659c0 .164 2.645 15.152 9.531 15.316h.055c5.62 0 8.76-11.693 9.476-16.25a.772.772 0 01.771-.659c.386 0 .716.275.772.659 0 .165 2.038 16.031 8.869 17.348 6.722 1.263 11.459-12.572 11.459-12.736.111-.384.551-.604.992-.494.386.11.606.549.496.988-.22.604-5.179 15.262-13.222 13.725-5.62-1.098-8.264-9.608-9.421-14.768-1.322 5.16-4.407 13.67-10.137 13.67h-.11c-5.729-.11-8.815-7.961-10.192-12.847-1.102 5.38-3.746 14.658-8.87 15.756-.33.055-.716.11-1.046.11-4.298 0-7.658-5.545-9.531-9.662 0 4.941-.882 12.243-5.399 13.67-.606.219-1.102.329-1.598.329z"
        fill="#FAFAFA"
      />
      <path
        d="M109.971 152.454a2.03 2.03 0 01-2.038 2.031c-1.102 0-2.038-.933-2.038-2.031a2.03 2.03 0 012.038-2.031 2.03 2.03 0 012.038 2.031zM125.728 145.592a2.03 2.03 0 01-2.038 2.031 2.03 2.03 0 01-2.039-2.031c0-1.098.937-2.032 2.039-2.032 1.157-.055 2.038.879 2.038 2.032zM145.891 140.377a2.03 2.03 0 01-2.038 2.031 2.03 2.03 0 110-4.063 2.03 2.03 0 012.038 2.032zM166.496 139.827a2.03 2.03 0 01-2.039 2.031 2.03 2.03 0 110-4.062 2.03 2.03 0 012.039 2.031zM188.588 142.792a2.03 2.03 0 01-2.039 2.031 2.03 2.03 0 01-2.038-2.031 2.03 2.03 0 012.038-2.032c1.102 0 2.039.934 2.039 2.032z"
        fill="#FAFAFA"
      />
      <path
        d="M113.773 223l-13.002-45.018s49.032-24.43 96.466-11.803L186.935 223h-73.162z"
        fill="url(#celebrateBirthDay42_linear)"
      />
      <path
        d="M117.685 199.063c10.137-2.305 6.886-23.442 6.886-23.442s4.022 21.192 14.379 18.502c10.357-2.691 8.429-25.364 8.429-25.364s3.802 21.411 13.387 21.795c9.586.329 12.066-21.795 12.066-21.795s-2.48 21.356 10.357 23.058c4.352.604 8.209-4.776 11.404-12.188l2.644-13.45c-47.489-12.627-96.466 11.803-96.466 11.803l2.92 9.059c4.022 6.807 9.146 13.12 13.994 12.022z"
        fill="url(#celebrateBirthDay43_linear)"
      />
      <path
        d="M117.85 207.847c-.772 0-1.543-.164-2.369-.549-7.823-3.678-15.151-25.253-15.481-26.187a.8.8 0 01.496-.988.803.803 0 01.991.494c.055.22 7.438 21.85 14.655 25.309.991.494 1.928.549 2.809.219 6.611-2.47 5.73-22.838 4.849-30.469a.735.735 0 01.606-.823.78.78 0 01.881.604c.055.219 4.738 20.697 11.955 25.253 1.212.769 2.369.989 3.581.714 7.603-1.757 6.721-24.101 5.729-32.555-.055-.439.221-.769.662-.824.44-.055.771.22.881.659 1.267 7.906 6.06 28.438 13.222 28.548h.055c6.832 0 9.862-19.215 10.578-27.67.055-.439.055-.768.055-.878.055-.439.386-.714.826-.714.441.055.716.385.716.824 0 .165 0 .329-.055.549-.275 3.733-1.267 25.199 8.65 27.834 1.542.384 2.92.165 4.297-.769 7.382-4.996 9.972-26.9 9.972-27.12a.739.739 0 01.826-.659.737.737 0 01.661.824c-.11.933-2.699 22.838-10.633 28.218-1.708 1.153-3.581 1.537-5.564.988-6.776-1.812-9.09-10.705-9.751-18.391-1.598 8.454-4.738 18.391-10.688 18.391h-.11c-6.171-.11-10.302-11.144-12.561-19.819.165 9.498-.882 22.454-7.548 23.937-1.597.384-3.195.055-4.738-.934-4.848-3.019-8.484-12.132-10.577-18.775.33 8.729-.276 22.069-6.391 24.375-.386.275-.936.384-1.487.384z"
        fill="#FAFAFA"
      />
      <path
        d="M104.958 182.484c0 1.317-1.101 2.415-2.424 2.415-1.322 0-2.424-1.098-2.424-2.415 0-1.318 1.102-2.416 2.424-2.416 1.323 0 2.424 1.098 2.424 2.416zM126.885 175.347c0 1.318-1.102 2.416-2.424 2.416-1.322 0-2.424-1.098-2.424-2.416 0-1.318 1.102-2.416 2.424-2.416 1.377 0 2.424 1.043 2.424 2.416zM149.858 169.034c0 1.317-1.102 2.415-2.424 2.415-1.322 0-2.424-1.098-2.424-2.415 0-1.318 1.102-2.416 2.424-2.416 1.322 0 2.424 1.098 2.424 2.416zM175.31 168.704c0 1.318-1.102 2.416-2.424 2.416-1.322 0-2.424-1.098-2.424-2.416 0-1.317 1.102-2.415 2.424-2.415 1.322 0 2.424 1.098 2.424 2.415zM198.999 170.516c0 1.317-1.101 2.415-2.424 2.415-1.322 0-2.424-1.098-2.424-2.415 0-1.318 1.102-2.416 2.424-2.416a2.405 2.405 0 012.424 2.416z"
        fill="#FAFAFA"
      />
      <path
        d="M271.587 91.592c-3.163 4.745-3.386 8.238-2.317 10.297 1.782 3.448 5.079 2.955 7.217 6.089 2.005 2.91 1.471 5.641 1.07 8.193"
        stroke="#5384EE"
        strokeWidth={0.529}
        strokeMiterlimit={10}
      />
      <path
        d="M271.677 96.114h-.981c-.133-.045-.222-.09-.356-.135a4.698 4.698 0 01-2.406-1.388c-.401-.492-.401-.94.045-1.388.178-.179.356-.268.579-.403.134-.09.267-.134.401-.223-.045-.09-.045-.135-.045-.135-.133-.09-.267-.134-.445-.224-1.96-1.03-3.698-2.328-5.168-4.03-3.341-3.805-4.99-8.327-5.257-13.341-.267-4.836.713-9.447 2.94-13.79 2.183-4.298 5.257-7.79 9.445-10.208 3.297-1.925 6.861-2.865 10.692-2.552 4.055.313 7.574 1.88 10.514 4.746 2.406 2.328 4.055 5.149 5.124 8.283 1.381 4.03 1.648 8.148.935 12.312-.802 4.88-2.717 9.268-5.925 13.029-2.361 2.776-5.123 5.014-8.554 6.402-3.519 1.433-7.128 1.836-10.826.627-.089-.045-.178 0-.311 0 .133.269.267.537.356.761.356.806.178 1.298-.668 1.612.044-.045 0 0-.089.045z"
        fill="url(#celebrateBirthDay44_linear)"
      />
      <path
        d="M296.804 61.236c-1.07-3.134-2.718-5.955-5.124-8.283-2.94-2.82-6.46-4.388-10.558-4.701-.713-.045-1.381-.045-2.094-.045-1.693.985-3.163 2.194-4.544 3.627-2.852 3-4.634 6.536-5.48 10.566-.758 3.447-.758 6.895.223 10.298.757 2.641 2.004 5.059 3.876 7.118 2.316 2.508 5.167 3.985 8.553 4.433 3.163.403 6.193-.224 9-1.657a19.151 19.151 0 006.014-4.79c.446-1.344.757-2.776 1.025-4.209.712-4.209.49-8.328-.891-12.357z"
        fill="url(#celebrateBirthDay45_linear)"
      />
      <path
        d="M293.907 69.787c-.089-.94-.089-1.925-.223-2.865-.267-2.15-.98-4.164-2.004-6.044-.223-.448-.58-.851-.981-1.164-.49-.403-.98-.18-.98.492 0 .448 0 .94.089 1.388.491 3.403.357 6.805.045 10.208-.089.895-.178 1.79-.178 2.686 0 .448.089.896.267 1.299.49 1.12 1.827 1.298 2.673.403.223-.224.357-.537.49-.806.178-.403.268-.806.357-1.209"
        fill="#fff"
      />
      <path
        d="M283.794 91.278c-4.143.806-8.242.448-12.118-1.254-4.143-1.835-7.262-4.79-9.266-8.82-1.649-3.313-2.362-6.85-2.362-10.521 0-4.701 1.248-9.134 3.52-13.253.312-.582.668-1.164 1.025-1.746-1.426 1.612-2.584 3.403-3.609 5.373-2.183 4.343-3.208 8.954-2.94 13.79.267 5.014 1.915 9.536 5.257 13.387a18.13 18.13 0 005.168 4.029c.133.09.267.134.445.224.045 0 .045.045.045.134a1.785 1.785 0 00-.401.224c-.179.134-.401.224-.58.403-.445.448-.445.94-.044 1.388.624.761 1.47 1.164 2.406 1.388.133.045.222.09.356.134h.98c.089-.045.134-.09.223-.134.802-.314 1.025-.806.668-1.612-.133-.224-.223-.493-.356-.761.133 0 .223-.045.312 0 3.697 1.209 7.306.806 10.826-.627a20.552 20.552 0 005.078-3c-1.514.493-3.029.94-4.633 1.254z"
        fill="url(#celebrateBirthDay46_linear)"
      />
      <path
        d="M283.082 103.456c-1.069 3.671-3.163 4.164-4.366 6.402-1.247 2.329-1.024 3.985-1.113 5.776"
        stroke="#5384EE"
        strokeWidth={0.529}
        strokeMiterlimit={10}
      />
      <path
        d="M284.641 105.113c-.356-.09-.713-.224-1.024-.314-.089-.089-.223-.179-.312-.268-.936-.538-1.649-1.299-2.094-2.284-.267-.627-.089-1.164.49-1.477.223-.135.49-.179.757-.224.179-.045.357-.045.49-.09v-.179l-.401-.403c-1.782-1.746-3.207-3.716-4.232-6-2.317-5.193-2.539-10.566-1.203-16.028 1.292-5.283 3.921-9.85 7.663-13.745 3.742-3.85 8.197-6.536 13.499-7.745 4.188-.94 8.286-.762 12.251.85 4.233 1.702 7.485 4.567 9.668 8.552 1.782 3.268 2.584 6.805 2.718 10.521.178 4.746-.936 9.223-3.074 13.477-2.451 4.925-5.97 8.954-10.603 11.909-3.431 2.194-7.129 3.627-11.227 3.94-4.233.358-8.198-.448-11.762-2.955-.089-.045-.178-.045-.312-.089.045.358.089.626.134.94.134.985-.267 1.432-1.247 1.522.044 0-.045.045-.179.09z"
        fill="url(#celebrateBirthDay47_linear)"
      />
      <path
        d="M323 76.28c-.133-3.716-.935-7.253-2.717-10.521-2.183-4.03-5.436-6.85-9.668-8.552-.757-.313-1.47-.537-2.227-.761-2.094.448-4.099 1.254-6.015 2.328-4.009 2.239-7.083 5.462-9.356 9.447-1.96 3.403-3.074 7.074-3.163 11.059-.044 3.09.446 6.089 1.782 8.865 1.649 3.447 4.233 5.955 7.663 7.566 3.252 1.523 6.683 1.836 10.158 1.254 2.94-.493 5.613-1.567 8.019-3.09.936-1.298 1.737-2.686 2.495-4.163 2.094-4.164 3.163-8.641 3.029-13.432z"
        fill="url(#celebrateBirthDay48_linear)"
      />
      <path
        d="M317.074 84.428c.223-1.03.535-2.06.713-3.134.401-2.373.312-4.746-.178-7.12a5.395 5.395 0 00-.668-1.566c-.401-.582-.981-.493-1.203.179a9.506 9.506 0 00-.401 1.522c-.579 3.806-1.871 7.388-3.342 10.925-.401.94-.757 1.88-1.069 2.82-.134.448-.223.985-.178 1.478.178 1.388 1.515 2.014 2.718 1.298.311-.179.579-.448.801-.716.312-.358.535-.761.802-1.164"
        fill="#fff"
      />
      <path
        d="M299.21 103.993c-4.723-.537-8.955-2.238-12.519-5.417-3.787-3.358-6.148-7.522-6.994-12.536-.669-4.075-.223-8.104.98-12 1.559-5.014 4.366-9.312 8.108-12.939.535-.537 1.114-1.03 1.648-1.522a27.329 27.329 0 00-5.613 4.567c-3.742 3.85-6.371 8.417-7.663 13.7-1.336 5.463-1.114 10.835 1.203 16.029a19.012 19.012 0 004.232 6l.401.402v.179c-.178.045-.356.045-.49.09-.267.045-.534.089-.757.224-.624.313-.802.85-.49 1.477.445.985 1.158 1.746 2.094 2.284.133.089.222.179.312.268.356.09.712.224 1.024.314.089 0 .178-.045.268-.045.98-.045 1.381-.537 1.247-1.522-.045-.314-.089-.582-.134-.941.134.045.223.045.312.09 3.52 2.507 7.529 3.313 11.762 2.955a21.694 21.694 0 006.415-1.522c-1.782.134-3.52.089-5.346-.135z"
        fill="url(#celebrateBirthDay49_linear)"
      />
      <defs>
        <linearGradient
          id="celebrateBirthDay0_linear"
          x1={149.916}
          y1={98.148}
          x2={149.916}
          y2={223}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#363636" />
          <stop offset={1} stopColor="#242424" />
        </linearGradient>
        <linearGradient
          id="celebrateBirthDay1_linear"
          x1={302.866}
          y1={173.132}
          x2={304.331}
          y2={187.582}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="celebrateBirthDay2_linear"
          x1={318.349}
          y1={185.801}
          x2={318.349}
          y2={228.85}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset={1} stopColor="#CCE1F7" />
        </linearGradient>
        <linearGradient
          id="celebrateBirthDay3_linear"
          x1={276.27}
          y1={222.623}
          x2={313.825}
          y2={221.545}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset={1} stopColor="#CCE1F7" />
        </linearGradient>
        <linearGradient
          id="celebrateBirthDay4_linear"
          x1={240.743}
          y1={196.48}
          x2={343.835}
          y2={166.068}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#92ADC9" />
          <stop offset={1} stopColor="#B3CCE6" />
        </linearGradient>
        <linearGradient
          id="celebrateBirthDay5_linear"
          x1={318.3}
          y1={179.209}
          x2={318.3}
          y2={195.09}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset={1} stopColor="#CCE1F7" />
        </linearGradient>
        <linearGradient
          id="celebrateBirthDay6_linear"
          x1={272.899}
          y1={192.793}
          x2={313.899}
          y2={189.289}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset={1} stopColor="#CCE1F7" />
        </linearGradient>
        <linearGradient
          id="celebrateBirthDay7_linear"
          x1={322.786}
          y1={188.942}
          x2={343.922}
          y2={200.764}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="celebrateBirthDay8_linear"
          x1={281.5}
          y1={196.488}
          x2={281.5}
          y2={237.539}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset={1} stopColor="#CCE1F7" />
        </linearGradient>
        <linearGradient
          id="celebrateBirthDay9_linear"
          x1={239.517}
          y1={231.601}
          x2={278.227}
          y2={230.401}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset={1} stopColor="#CCE1F7" />
        </linearGradient>
        <linearGradient
          id="celebrateBirthDay10_linear"
          x1={205.983}
          y1={205.886}
          x2={304.543}
          y2={173.736}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#92ADC9" />
          <stop offset={1} stopColor="#B3CCE6" />
        </linearGradient>
        <linearGradient
          id="celebrateBirthDay11_linear"
          x1={281.2}
          y1={190.595}
          x2={281.2}
          y2={205.178}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset={1} stopColor="#CCE1F7" />
        </linearGradient>
        <linearGradient
          id="celebrateBirthDay12_linear"
          x1={235.936}
          y1={203.068}
          x2={278.235}
          y2={198.998}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset={1} stopColor="#CCE1F7" />
        </linearGradient>
        <linearGradient
          id="celebrateBirthDay13_linear"
          x1={287.438}
          y1={199.665}
          x2={305.871}
          y2={209.032}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="celebrateBirthDay14_linear"
          x1={213.135}
          y1={174.282}
          x2={214.85}
          y2={190.431}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="celebrateBirthDay15_linear"
          x1={236.95}
          y1={189.297}
          x2={236.95}
          y2={250.124}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset={1} stopColor="#CCE1F7" />
        </linearGradient>
        <linearGradient
          id="celebrateBirthDay16_linear"
          x1={222.115}
          y1={241.326}
          x2={271.731}
          y2={239.995}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset={1} stopColor="#CCE1F7" />
        </linearGradient>
        <linearGradient
          id="celebrateBirthDay17_linear"
          x1={139.742}
          y1={203.564}
          x2={270.137}
          y2={167.5}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#92ADC9" />
          <stop offset={1} stopColor="#B3CCE6" />
        </linearGradient>
        <linearGradient
          id="celebrateBirthDay18_linear"
          x1={237.25}
          y1={180.907}
          x2={237.25}
          y2={201.682}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset={1} stopColor="#CCE1F7" />
        </linearGradient>
        <linearGradient
          id="celebrateBirthDay19_linear"
          x1={219.692}
          y1={198.421}
          x2={273.801}
          y2={193.685}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset={1} stopColor="#CCE1F7" />
        </linearGradient>
        <linearGradient
          id="celebrateBirthDay20_linear"
          x1={221.556}
          y1={194.854}
          x2={246.847}
          y2={206.47}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="celebrateBirthDay21_linear"
          x1={248.055}
          y1={184.238}
          x2={257.731}
          y2={205.672}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="celebrateBirthDay22_linear"
          x1={201.084}
          y1={188.334}
          x2={206.114}
          y2={207.905}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="celebrateBirthDay23_linear"
          x1={27}
          y1={173.059}
          x2={27}
          y2={226.788}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset={1} stopColor="#CCE1F7" />
        </linearGradient>
        <linearGradient
          id="celebrateBirthDay24_linear"
          x1={13.857}
          y1={219.108}
          x2={56.056}
          y2={218.02}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset={1} stopColor="#CCE1F7" />
        </linearGradient>
        <linearGradient
          id="celebrateBirthDay25_linear"
          x1={-54.402}
          y1={187.032}
          x2={56.806}
          y2={160.792}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#92ADC9" />
          <stop offset={1} stopColor="#B3CCE6" />
        </linearGradient>
        <linearGradient
          id="celebrateBirthDay26_linear"
          x1={26.9}
          y1={165.069}
          x2={26.9}
          y2={185.509}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset={1} stopColor="#CCE1F7" />
        </linearGradient>
        <linearGradient
          id="celebrateBirthDay27_linear"
          x1={11.454}
          y1={181.017}
          x2={57.777}
          y2={177.21}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset={1} stopColor="#CCE1F7" />
        </linearGradient>
        <linearGradient
          id="celebrateBirthDay28_linear"
          x1={13.57}
          y1={177.64}
          x2={40.852}
          y2={194.844}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="celebrateBirthDay29_linear"
          x1={28.965}
          y1={178.458}
          x2={30.268}
          y2={191.591}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="celebrateBirthDay30_linear"
          x1={51.85}
          y1={190.838}
          x2={51.85}
          y2={235.085}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset={1} stopColor="#CCE1F7" />
        </linearGradient>
        <linearGradient
          id="celebrateBirthDay31_linear"
          x1={42.082}
          y1={228.685}
          x2={79.407}
          y2={227.649}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset={1} stopColor="#CCE1F7" />
        </linearGradient>
        <linearGradient
          id="celebrateBirthDay32_linear"
          x1={-25.756}
          y1={201.859}
          x2={77.843}
          y2={172.246}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#92ADC9" />
          <stop offset={1} stopColor="#B3CCE6" />
        </linearGradient>
        <linearGradient
          id="celebrateBirthDay33_linear"
          x1={51.9}
          y1={184.146}
          x2={51.9}
          y2={200.327}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset={1} stopColor="#CCE1F7" />
        </linearGradient>
        <linearGradient
          id="celebrateBirthDay34_linear"
          x1={40.1}
          y1={197.986}
          x2={81.11}
          y2={194.546}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset={1} stopColor="#CCE1F7" />
        </linearGradient>
        <linearGradient
          id="celebrateBirthDay35_linear"
          x1={40.087}
          y1={194.147}
          x2={61.457}
          y2={205.822}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="celebrateBirthDay36_linear"
          x1={143.196}
          y1={97.995}
          x2={147.989}
          y2={97.995}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="celebrateBirthDay37_linear"
          x1={145.561}
          y1={68.183}
          x2={145.561}
          y2={86.465}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6D8CC2" />
          <stop offset={1} stopColor="#98C0E8" />
        </linearGradient>
        <linearGradient
          id="celebrateBirthDay38_linear"
          x1={117.835}
          y1={125.703}
          x2={176.408}
          y2={125.703}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="celebrateBirthDay39_linear"
          x1={147.076}
          y1={109.592}
          x2={147.076}
          y2={130.935}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6D8CC2" />
          <stop offset={1} stopColor="#98C0E8" />
        </linearGradient>
        <linearGradient
          id="celebrateBirthDay40_linear"
          x1={106.832}
          y1={155.19}
          x2={187.445}
          y2={155.19}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="celebrateBirthDay41_linear"
          x1={147.076}
          y1={136.26}
          x2={147.076}
          y2={158.931}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6D8CC2" />
          <stop offset={1} stopColor="#98C0E8" />
        </linearGradient>
        <linearGradient
          id="celebrateBirthDay42_linear"
          x1={100.838}
          y1={192.806}
          x2={197.32}
          y2={192.806}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="celebrateBirthDay43_linear"
          x1={149.004}
          y1={162.555}
          x2={149.004}
          y2={199.188}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6D8CC2" />
          <stop offset={1} stopColor="#98C0E8" />
        </linearGradient>
        <linearGradient
          id="celebrateBirthDay44_linear"
          x1={213.647}
          y1={89.189}
          x2={306.252}
          y2={83.277}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset={1} stopColor="#CCE1F7" />
        </linearGradient>
        <linearGradient
          id="celebrateBirthDay45_linear"
          x1={235.668}
          y1={79.138}
          x2={304.146}
          y2={74.86}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D7E7F7" />
          <stop offset={1} stopColor="#CCE1F7" />
        </linearGradient>
        <linearGradient
          id="celebrateBirthDay46_linear"
          x1={224.329}
          y1={90.304}
          x2={294.689}
          y2={86.27}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D7E7F7" />
          <stop offset={1} stopColor="#CCE1F7" />
        </linearGradient>
        <linearGradient
          id="celebrateBirthDay47_linear"
          x1={224.674}
          y1={98.468}
          x2={332.335}
          y2={90.795}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset={1} stopColor="#CCE1F7" />
        </linearGradient>
        <linearGradient
          id="celebrateBirthDay48_linear"
          x1={253.152}
          y1={91.338}
          x2={329.793}
          y2={86.587}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D7E7F7" />
          <stop offset={1} stopColor="#CCE1F7" />
        </linearGradient>
        <linearGradient
          id="celebrateBirthDay49_linear"
          x1={245.103}
          y1={99.055}
          x2={310.436}
          y2={96.009}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D7E7F7" />
          <stop offset={1} stopColor="#CCE1F7" />
        </linearGradient>
      </defs>
    </svg>
  ) : (
    <svg
      width={344}
      height={223}
      viewBox="0 0 344 223"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M69.5 223s-39.738-19.236-38.813-53.271c.924-34.035 38.01-39.359 63.384-18.375 25.477 20.985 38.52 10.719 51.669-7.029 13.15-17.749 33.39-57.908 70.27-42.875 27.532 11.171 45.92 41.135 51.878 68.175 4.11 18.583-1.849 38.211-16.128 50.426-.514.417-.76 1.95-1.76 2.949H69.5z"
        fill="url(#celebrateBirthDay0_linear)"
      />
      <path
        d="M149.647 35.419c-.515.097-.966-.032-1.408-.304-.448-.307-.903-.647-1.172-1.13-.262-.448-.427-.95-.387-1.49.049-.683.375-1.028 1.021-1.185.646-.156 1.212.021 1.659.506.534.574.904 1.215 1.183 1.944.073.2.112.405.116.617.002.39-.152.703-.551.849-.09.088-.296.127-.461.193z"
        fill="#5384EE"
        fillOpacity={0.2}
      />
      <path
        d="M171.872 88.582c-.514.097-.965-.031-1.407-.304-.449-.306-.904-.647-1.172-1.13-.262-.447-.428-.949-.387-1.489.049-.684.375-1.029 1.02-1.185.646-.157 1.213.02 1.659.505.535.575.904 1.216 1.183 1.944.074.2.112.405.117.618.002.39-.152.703-.551.849-.09.087-.29.16-.462.192z"
        fill="#507BDD"
      />
      <path
        d="M38.34 113.209c-.516.097-.966-.032-1.409-.304-.448-.307-.903-.647-1.171-1.13-.262-.447-.428-.949-.387-1.489.049-.684.374-1.029 1.02-1.186.646-.156 1.212.022 1.659.506.535.574.904 1.215 1.183 1.944.073.199.112.405.117.617.002.39-.152.703-.551.849-.09.088-.297.127-.462.193z"
        fill="#FF8C00"
      />
      <path
        d="M75.135 115.323c-.453.049-.871.092-1.288-.043-.45-.128-.783-.385-.908-.859a6.742 6.742 0 01-.22-1.166c-.055-.487.196-.853.657-1.046a3.34 3.34 0 01.77-.251c.624-.082 1.15.068 1.534.6.25.379.56.711.803 1.056.35.538.278.907-.26 1.257-.125.094-.29.16-.421.22-.227.114-.433.153-.667.232zM66.133 83.712c-.453.05-.871.092-1.288-.043-.45-.128-.783-.386-.908-.86a6.727 6.727 0 01-.22-1.165c-.055-.487.196-.854.657-1.047.262-.12.53-.205.77-.25.624-.082 1.15.068 1.535.6.249.379.56.711.803 1.056.35.538.277.907-.261 1.256-.125.095-.29.161-.421.221-.227.114-.433.152-.667.232zM130.632 50.169c.059-.438.16-.847.405-1.248.244-.401.589-.643 1.055-.624.397.032.822.023 1.191.096.478.088.755.427.846.907.051.274.069.555.011.815-.096.621-.403 1.07-1.036 1.294-.433.153-.826.333-1.266.45-.605.185-.959.003-1.144-.602l-.09-.48c.009-.143.038-.362.028-.608z"
        fill="#5384EE"
        fillOpacity={0.2}
      />
      <path
        d="M209.513 38.671c-.447.084-.809.045-1.176-.205a.67.67 0 01-.316-.545c.008-.712.085-1.436.456-2.11.315-.59.819-.934 1.491-.953.671-.02 1.086.293 1.174.951.09.48.078.98-.004 1.492-.072.369-.261.688-.578.89-.318.201-.635.403-1.047.48z"
        fill="#FF8C00"
      />
      <path
        d="M197.033 71.543c-.446.084-.809.045-1.176-.206a.667.667 0 01-.315-.544c.008-.712.085-1.436.456-2.11.315-.591.819-.934 1.49-.954.672-.019 1.086.294 1.175.952.09.48.077.98-.004 1.492-.073.369-.261.688-.579.89-.317.201-.669.41-1.047.48z"
        fill="#5384EE"
        fillOpacity={0.2}
      />
      <path
        d="M79.959 66.882c.178.57-.129 1.018-.633 1.362-.731.456-1.506.495-2.255.103a.908.908 0 01-.412-.491c-.224-.81-.01-1.56.512-2.19.437-.509 1.014-.653 1.67-.563.295.052.512.26.666.515.168.323.355.75.452 1.264zM205.419 26.162c.103.55.011.815-.431 1.11-.663.445-1.377.614-2.148.297-.382-.142-.489-.334-.451-.697.026-.43.051-.861.111-1.299-.006-.034-.013-.068.015-.109.079-.902.564-1.348 1.461-1.303.356.004.628.13.858.407.331.435.462.943.587 1.416-.022.075-.015.11-.002.178z"
        fill="#507BDD"
      />
      <path
        d="M82.22 97.237c-.2-.495-.18-.96.05-1.43.226-.504.485-1.014.914-1.378.395-.358.85-.585 1.371-.648.7-.06 1.094.15 1.36.81.26.626.192 1.207-.204 1.743-.453.618-1.034 1.117-1.703 1.527-.193.107-.393.18-.599.218-.405.112-.719-.042-.92-.36-.101-.158-.168-.323-.269-.482z"
        fill="#FF8C00"
      />
      <path
        d="M26.037 90.702c-.495.2-.79.148-1.182-.24-.57-.568-.836-1.228-.703-2.034.073-.37.219-.538.603-.575l1.29-.135.102-.02c.906-.098 1.408.305 1.576 1.196a.994.994 0 01-.257.9c-.348.421-.843.62-1.298.848-.028.04-.096.053-.13.06z"
        fill="#507BDD"
      />
      <path
        d="M39.16 61.231c2.01.866 7.092 2.186 7.327-1.48.123-1.799-1.899-2.911-3.411-1.882-2.3 1.567-.476 5.025 1.23 6.161 2.727 1.833 6.312.486 7.613-2.421.253-.545-.585-1.027-.831-.448-.724 1.627-2.245 2.8-4.06 2.785-1.96-.023-4.077-1.828-4.033-3.86.062-2.32 3.077-1.784 2.524-.19-.326.914-1.688 1.418-2.579 1.407-1.171.007-2.262-.499-3.305-.942-.573-.213-1.055.623-.476.87zM186.543 3.892c.874 2.002 3.666 6.45 6.325 3.963 1.33-1.244.635-3.421-1.163-3.723-2.748-.444-3.785 3.301-3.329 5.346.744 3.198 4.261 4.704 7.187 3.446.558-.247.246-1.147-.311-.9-1.646.699-3.568.49-4.875-.79-1.389-1.338-1.693-4.086-.259-5.527 1.643-1.657 3.464.842 1.969 1.584-.875.448-2.197-.156-2.841-.745-.861-.798-1.321-1.919-1.768-2.971-.281-.55-1.189-.274-.935.317z"
        fill="#5384EE"
        fillOpacity={0.2}
      />
      <path
        d="M93.227 50.477c1.647-1.445 3.588-3.407 2.773-5.846-.563-1.67-2.827-2.559-4.124-1.144-1.388 1.503.254 3.432 1.776 3.963 2.123.703 4.448-.159 5.79-1.902.37-.495-.406-1.025-.774-.53-.828 1.079-2.083 1.776-3.474 1.752-1.103-.006-4.257-1.474-2.269-2.805 1.795-1.224 2.612 1.605 2.2 2.818-.404 1.248-1.51 2.166-2.452 3.017-.539.35.097 1.082.554.677z"
        fill="#FF8C00"
      />
      <path
        d="M338.999 177.211c-1.2-4.794-7.5-4.794-11.1-1.898-1.1.899-2.1 2.098-2.9 3.197-.2-.4-.5-.7-1-.7l-4.2-.399c-.4 0-.8.2-1.1.499-.6-1.298-1.4-2.597-2.3-3.695-3-3.596-9.2-4.695-11.3-.2-2.1 4.495 1.8 6.892 3.1 7.99 1 .799 6.9.4 10 .2.1.5.5.999 1.1.999l4.2.4c.6.099 1.1-.3 1.2-.8 2.9.8 8.7 2.298 9.8 1.698 1.6-.899 5.8-2.497 4.5-7.291zm-28.3-1.198c1.7-1.099 5.4 1.897 7.7 3.795l-.2 1.798c-1.1-.3-2.7-.699-4-1.099-2.6-.699-5.9-2.896-3.5-4.494zm18.3 5.893c-1.4.099-3 .199-4.2.299l.2-1.798c2.6-1.498 6.8-3.695 8.3-2.397 2.1 2.098-1.6 3.696-4.3 3.896z"
        fill="url(#celebrateBirthDay1_linear)"
      />
      <path
        d="M294.199 189.696L297 223h43l2.499-31.905-32.1-5.294-16.2 3.895z"
        fill="url(#celebrateBirthDay2_linear)"
      />
      <path
        d="M294.199 189.696l16.2-3.895L310 223h-13.5l-2.301-33.304z"
        fill="url(#celebrateBirthDay3_linear)"
      />
      <path
        d="M294.199 189.696l16.2-3.895 32.1 5.294-.5 7.191-31.5-2.996-15.7 2.097-.6-7.691z"
        fill="url(#celebrateBirthDay4_linear)"
      />
      <path
        d="M343.4 194.99l-33.2-3.196-16.6 3.296-1-8.89 17.8-6.991 33.6 6.792-.6 8.989z"
        fill="url(#celebrateBirthDay5_linear)"
      />
      <path
        d="M310.2 191.794l-16.6 3.296-1-8.89 17.8-6.991-.1 12.685-.1-.1z"
        fill="url(#celebrateBirthDay6_linear)"
      />
      <path
        d="M329.8 183.004L328.5 223H323l1.6-41.094 5.2 1.098z"
        fill="url(#celebrateBirthDay7_linear)"
      />
      <path
        d="M258 200.084L259 223h45.5l.5-21.818-30.3-4.694-16.7 3.596z"
        fill="#5ED6CC"
      />
      <path
        d="M258 200.084L259.5 223h45l.5-21.818-30.3-4.694-16.7 3.596z"
        fill="url(#celebrateBirthDay8_linear)"
      />
      <path
        d="M258 200.084l16.7-3.596V223h-15.2l-1.5-22.916z"
        fill="url(#celebrateBirthDay9_linear)"
      />
      <path
        d="M258 200.084l16.7-3.596 30.3 4.694-.2 6.293-29.9-2.297-16.4 2.197-.5-7.291z"
        fill="url(#celebrateBirthDay10_linear)"
      />
      <path
        d="M305.9 204.578l-31.4-2.597-17.3 3.197-.9-8.49 18.4-6.093 31.4 6.292-.2 7.691z"
        fill="url(#celebrateBirthDay11_linear)"
      />
      <path
        d="M274.5 201.981l-17.3 3.197-.9-8.49 18.4-6.093v11.386h-.2z"
        fill="url(#celebrateBirthDay12_linear)"
      />
      <path
        d="M293.3 194.191V223h-4.8v-29.808l4.8.999z"
        fill="url(#celebrateBirthDay13_linear)"
      />
      <path
        d="M251.3 175.013c-2.3-4.794-8.8-3.396-12 .5-.9 1.198-1.7 2.697-2.3 3.995-.3-.399-.7-.599-1.2-.499l-4.5.499c-.5.1-.9.4-1 .799-.9-1.198-2-2.497-3.2-3.396-4-3.096-10.7-2.896-12 2.297-1.3 5.294 3.3 6.892 4.9 7.791 1.2.699 7.4-1.099 10.6-2.097.2.599.7.899 1.4.899l4.6-.6c.6-.1 1.1-.599 1.1-1.198 3.3.199 9.7.499 10.7-.5 1.2-.998 5.2-3.695 2.9-8.49zm-29.9 5.194c1.5-1.498 6.2.699 8.9 2.298l.2 1.897c-1.3 0-3-.1-4.5-.2-2.9-.099-6.9-1.797-4.6-3.995zm20.5 2.098c-1.4.499-3.1.899-4.3 1.298l-.2-1.898c2.3-2.197 6.3-5.493 8.1-4.394 2.6 1.598-.8 4.095-3.6 4.994z"
        fill="url(#celebrateBirthDay14_linear)"
      />
      <path
        d="M267.2 194.091l-1.7 28.908h-58.4l-.4-26.211 39.1-7.491 21.4 4.794z"
        fill="url(#celebrateBirthDay15_linear)"
      />
      <path
        d="M267.4 193.434L246 188.64v34.359h19.5l1.9-29.565z"
        fill="url(#celebrateBirthDay16_linear)"
      />
      <path
        d="M267.2 194.091l-21.4-4.794-39.1 7.491.2 9.189 38.9-4.195 21 2.797.4-10.488z"
        fill="url(#celebrateBirthDay17_linear)"
      />
      <path
        d="M205.4 201.682l40.8-4.495 22.1 4.195.9-12.085-23.5-8.39-40.4 9.588.1 11.187z"
        fill="url(#celebrateBirthDay18_linear)"
      />
      <path
        d="M246.2 197.187l22.1 4.195.9-12.085-23.5-8.39.2 16.38.3-.1z"
        fill="url(#celebrateBirthDay19_linear)"
      />
      <path
        d="M222 187.014l.493 35.986H229v-37.955l-7 1.969z"
        fill="url(#celebrateBirthDay20_linear)"
      />
      <path
        d="M248.7 182.305s2.7 1.298 4.6 6.492c1.9 5.194 1.4 15.781 1.4 15.781l3.2-9.988 4.6 9.689s-1.3-15.482-7.3-22.573c0 0-4.2-2.897-6.5.599z"
        fill="url(#celebrateBirthDay21_linear)"
      />
      <path
        d="M221.6 186.6s-2.9-2.297-7.6-.599c-4.7 1.698-12 15.681-12 15.681l7.7-4.894s.1 8.19.7 7.99c.6-.199 4.9-12.185 7.2-14.682 2.4-2.597 4-3.496 4-3.496z"
        fill="url(#celebrateBirthDay22_linear)"
      />
      <path
        d="M52.2 176.954L51 223H5.5l-3.9-41.95 32.4-7.991 18.2 3.895z"
        fill="#5ED6CC"
      />
      <path
        d="M52 176.947L50.815 222.9l-14.822.1H5.556L2 181.034l32.016-7.975L52 176.947z"
        fill="url(#celebrateBirthDay23_linear)"
      />
      <path
        d="M52.2 176.954L34 173.059 36.5 223h15l.7-46.046z"
        fill="url(#celebrateBirthDay24_linear)"
      />
      <path
        d="M52.2 176.934L34 173.045l-32.4 7.977.7 8.376 32.1-4.786 17.6 2.094.2-9.772z"
        fill="url(#celebrateBirthDay25_linear)"
      />
      <path
        d="M.9 185.509l33.7-5.085 18.6 3.29.6-11.367-20.1-7.278L0 175.239l.9 10.27z"
        fill="url(#celebrateBirthDay26_linear)"
      />
      <path
        d="M34.6 180.424l18.6 3.29.6-11.367-20.1-7.278.7 15.355h.2z"
        fill="url(#celebrateBirthDay27_linear)"
      />
      <path
        d="M14 170.671L17.31 223H23l-3.724-53.936L14 170.671z"
        fill="url(#celebrateBirthDay28_linear)"
      />
      <path
        d="M62.2 179.352c-1.9-4.195-7.7-3.197-10.5.099-1.1 1.299-2 3.097-2.6 4.495-.2-.2-.4-.3-.7-.3l-3.3.3c-.3 0-.5.2-.7.4-.8-1.299-2-2.897-3.3-3.996-3.4-2.796-9.2-2.796-10.4 1.599-1.2 4.494 2.7 5.992 4.1 6.791 1.1.7 7.1-.898 9.6-1.598v.4c0 .499.5.899 1 .899l3.3-.3c.5 0 .9-.499.9-.999v-.399c2.7.299 8.8.799 9.8 0 1.1-.999 4.7-3.097 2.8-7.391zm-22.3 7.191c-2.5-.3-5.9-1.798-3.9-3.596 1.5-1.298 5.9 1.199 8.2 2.597l.1 1.399c-1.1-.1-2.9-.3-4.4-.4zm13.8-1.199c-1.5.4-3.2.8-4.3 1.099l-.1-1.398c2-1.798 6-4.994 7.7-3.896 2.3 1.499-.8 3.596-3.3 4.195z"
        fill="url(#celebrateBirthDay29_linear)"
      />
      <path
        d="M76 194.833l-2 28.166H29.5l-1.8-26.768 32.2-5.393 16.1 3.995z"
        fill="url(#celebrateBirthDay30_linear)"
      />
      <path
        d="M76 194.833l-16.1-3.995v32.161H74l2-28.166z"
        fill="url(#celebrateBirthDay31_linear)"
      />
      <path
        d="M76 194.833l-16.1-3.995-32.2 5.393.5 7.492 31.5-3.197 15.7 2.198.6-7.891z"
        fill="url(#celebrateBirthDay32_linear)"
      />
      <path
        d="M26.8 200.227l33.2-3.196 16.6 3.296 1-9.09-17.8-7.091-33.6 6.892.6 9.189z"
        fill="url(#celebrateBirthDay33_linear)"
      />
      <path
        d="M60 197.031l16.6 3.296 1-9.09-17.8-7.091.1 12.885h.1z"
        fill="url(#celebrateBirthDay34_linear)"
      />
      <path
        d="M40.4 188.041L41.5 223H47l-1.4-36.057-5.2 1.098z"
        fill="url(#celebrateBirthDay35_linear)"
      />
      <path
        d="M144.515 111.389l-1.323-26.132s2.314-1.537 4.793 0v26.132h-3.47z"
        fill="url(#celebrateBirthDay36_linear)"
      />
      <path
        d="M145.561 68.183s-11.073 18.282 0 18.282c11.074 0 0-18.282 0-18.282z"
        fill="url(#celebrateBirthDay37_linear)"
      />
      <path
        d="M145.561 73.509s-6.721 11.034 0 11.034c6.722 0 0-11.034 0-11.034z"
        fill="#D7E9FF"
      />
      <path
        d="M123.139 141.859l-5.344-26.352s27.931-11.639 58.563-2.471l-3.086 25.968c.055 0-22.367-3.788-50.133 2.855z"
        fill="url(#celebrateBirthDay38_linear)"
      />
      <path
        d="M127.436 130.879c6.886 1.153 5.179-15.811 5.179-15.811s4.187 15.427 8.208 13.725c4.022-1.702 4.959-16.196 4.959-16.196s4.021 16.58 10.357 16.031c6.115-.549 8.098-13.945 8.209-14.823v-.055.055c-.111.878-1.929 14.658 2.699 14.658 2.644 0 6.005-4.447 8.484-8.399l.827-6.918c-30.632-9.223-58.563 2.416-58.563 2.416l1.322 6.423c2.204 4.282 5.124 8.345 8.319 8.894z"
        fill="url(#celebrateBirthDay39_linear)"
      />
      <path
        d="M128.262 134.393c-5.124 0-9.806-12.737-10.302-14.274-.165-.384.055-.824.441-.988.385-.165.826.055.991.439 1.928 5.38 6.06 13.725 9.09 13.231 2.865-.439 3.692-10.651 3.361-17.733a.77.77 0 01.661-.768c.386-.055.771.219.826.604 1.984 8.125 5.344 16.744 7.438 16.744h.055c2.093-.22 3.801-10.102 4.187-19.105 0-.384.33-.659.661-.714.386-.055.716.22.826.549 1.818 7.027 5.95 18.227 9.366 18.282h.055c3.361 0 6.446-10.431 7.603-17.019.055-.439.496-.714.881-.604.441.055.716.494.606.878-1.157 6.588-1.597 16.196 1.047 16.964 2.644.769 6.776-7.082 8.87-12.901.165-.384.606-.604.991-.439.386.164.606.604.441.988-.936 2.58-5.784 15.262-10.743 13.835-2.424-.714-3.085-4.612-3.085-8.729-1.542 4.391-3.746 8.619-6.666 8.619h-.055c-4.187-.11-7.768-9.443-9.586-15.207-.551 6.368-1.928 15.811-5.289 16.14-3.085.329-5.895-6.643-7.603-12.297-.165 5.599-1.101 12.901-4.682 13.505-.055-.055-.221 0-.386 0z"
        fill="#FAFAFA"
      />
      <path
        d="M120.219 119.46c0 .878-.716 1.537-1.543 1.537-.826 0-1.542-.714-1.542-1.537 0-.879.716-1.538 1.542-1.538.827 0 1.543.714 1.543 1.538zM134.102 114.848c0 .879-.716 1.537-1.543 1.537a1.536 1.536 0 110-3.074c.827 0 1.543.659 1.543 1.537zM145.891 114.464a1.54 1.54 0 001.543-1.537 1.54 1.54 0 00-1.543-1.538 1.54 1.54 0 00-1.542 1.538c0 .849.69 1.537 1.542 1.537zM165.945 113.696c0 .879-.716 1.537-1.542 1.537a1.537 1.537 0 110-3.074c.881 0 1.542.714 1.542 1.537zM175.752 118.746a1.54 1.54 0 001.542-1.537 1.54 1.54 0 00-1.542-1.537 1.54 1.54 0 00-1.543 1.537 1.54 1.54 0 001.543 1.537z"
        fill="#FAFAFA"
      />
      <path
        d="M114.434 174.084l-7.658-24.924s26.61-19.27 80.6-10.705l-4.187 28.383s-39.832-2.306-68.755 7.246z"
        fill="url(#celebrateBirthDay40_linear)"
      />
      <path
        d="M116.528 158.877c9.365-1.153 7.106-13.176 7.106-13.176s4.573 12.188 12.451 9.333c7.823-2.854 7.823-14.987 7.823-14.987s1.047 13.231 10.743 11.968c9.641-1.263 9.862-12.902 9.862-12.902s.936 12.957 9.861 12.902c5.895-.055 9.917-4.831 11.955-8.125l1.047-5.435c-53.99-8.565-80.6 10.705-80.6 10.705l2.865 6.972c1.873 1.867 4.132 3.075 6.887 2.745z"
        fill="url(#celebrateBirthDay41_linear)"
      />
      <path
        d="M117.629 164.093c-6.115 0-10.247-11.364-10.412-11.858-.166-.384.055-.823.44-.988.386-.165.827.055.992.439 1.267 3.459 5.454 12.133 10.082 10.705 4.683-1.482 4.572-12.462 4.132-16.579a.736.736 0 01.606-.824c.385-.055.771.165.881.494 1.267 3.898 5.62 13.725 10.523 12.682 4.958-1.043 7.658-13.505 8.319-18.226a.77.77 0 01.771-.659.77.77 0 01.771.659c0 .164 2.645 15.152 9.531 15.316h.055c5.62 0 8.76-11.693 9.476-16.25a.772.772 0 01.771-.659c.386 0 .716.275.772.659 0 .165 2.038 16.031 8.869 17.348 6.722 1.263 11.459-12.572 11.459-12.736.111-.384.551-.604.992-.494.386.11.606.549.496.988-.22.604-5.179 15.262-13.222 13.725-5.62-1.098-8.264-9.608-9.421-14.768-1.322 5.16-4.407 13.67-10.137 13.67h-.11c-5.729-.11-8.815-7.961-10.192-12.847-1.102 5.38-3.746 14.658-8.87 15.756-.33.055-.716.11-1.046.11-4.298 0-7.658-5.545-9.531-9.662 0 4.941-.882 12.243-5.399 13.67-.606.219-1.102.329-1.598.329z"
        fill="#FAFAFA"
      />
      <path
        d="M109.971 152.454a2.03 2.03 0 01-2.038 2.031c-1.102 0-2.038-.933-2.038-2.031a2.03 2.03 0 012.038-2.031 2.03 2.03 0 012.038 2.031zM125.728 145.592a2.03 2.03 0 01-2.038 2.031 2.03 2.03 0 01-2.039-2.031c0-1.098.937-2.032 2.039-2.032 1.157-.055 2.038.879 2.038 2.032zM145.891 140.377a2.03 2.03 0 01-2.038 2.031 2.03 2.03 0 110-4.063 2.03 2.03 0 012.038 2.032zM166.496 139.827a2.03 2.03 0 01-2.039 2.031 2.03 2.03 0 110-4.062 2.03 2.03 0 012.039 2.031zM188.588 142.792a2.03 2.03 0 01-2.039 2.031 2.03 2.03 0 01-2.038-2.031 2.03 2.03 0 012.038-2.032c1.102 0 2.039.934 2.039 2.032z"
        fill="#FAFAFA"
      />
      <path
        d="M113.773 223l-13.002-45.018s49.032-24.43 96.466-11.803L186.935 223h-73.162z"
        fill="url(#celebrateBirthDay42_linear)"
      />
      <path
        d="M117.685 199.063c10.137-2.305 6.886-23.442 6.886-23.442s4.022 21.192 14.379 18.502c10.357-2.691 8.429-25.364 8.429-25.364s3.802 21.411 13.387 21.795c9.586.329 12.066-21.795 12.066-21.795s-2.48 21.356 10.357 23.058c4.352.604 8.209-4.776 11.404-12.188l2.644-13.45c-47.489-12.627-96.466 11.803-96.466 11.803l2.92 9.059c4.022 6.807 9.146 13.12 13.994 12.022z"
        fill="url(#celebrateBirthDay43_linear)"
      />
      <path
        d="M117.85 207.847c-.772 0-1.543-.164-2.369-.549-7.823-3.678-15.151-25.253-15.481-26.187a.8.8 0 01.496-.988.803.803 0 01.991.494c.055.22 7.438 21.85 14.655 25.309.991.494 1.928.549 2.809.219 6.611-2.47 5.73-22.838 4.849-30.469a.735.735 0 01.606-.823.78.78 0 01.881.604c.055.219 4.738 20.697 11.955 25.253 1.212.769 2.369.989 3.581.714 7.603-1.757 6.721-24.101 5.729-32.555-.055-.439.221-.769.662-.824.44-.055.771.22.881.659 1.267 7.906 6.06 28.438 13.222 28.548h.055c6.832 0 9.862-19.215 10.578-27.67.055-.439.055-.768.055-.878.055-.439.386-.714.826-.714.441.055.716.385.716.824 0 .165 0 .329-.055.549-.275 3.733-1.267 25.199 8.65 27.834 1.542.384 2.92.165 4.297-.769 7.382-4.996 9.972-26.9 9.972-27.12a.739.739 0 01.826-.659.737.737 0 01.661.824c-.11.933-2.699 22.838-10.633 28.218-1.708 1.153-3.581 1.537-5.564.988-6.776-1.812-9.09-10.705-9.751-18.391-1.598 8.454-4.738 18.391-10.688 18.391h-.11c-6.171-.11-10.302-11.144-12.561-19.819.165 9.498-.882 22.454-7.548 23.937-1.597.384-3.195.055-4.738-.934-4.848-3.019-8.484-12.132-10.577-18.775.33 8.729-.276 22.069-6.391 24.375-.386.275-.936.384-1.487.384z"
        fill="#FAFAFA"
      />
      <path
        d="M104.958 182.484c0 1.317-1.101 2.415-2.424 2.415-1.322 0-2.424-1.098-2.424-2.415 0-1.318 1.102-2.416 2.424-2.416 1.323 0 2.424 1.098 2.424 2.416zM126.885 175.347c0 1.318-1.102 2.416-2.424 2.416-1.322 0-2.424-1.098-2.424-2.416 0-1.318 1.102-2.416 2.424-2.416 1.377 0 2.424 1.043 2.424 2.416zM149.858 169.034c0 1.317-1.102 2.415-2.424 2.415-1.322 0-2.424-1.098-2.424-2.415 0-1.318 1.102-2.416 2.424-2.416 1.322 0 2.424 1.098 2.424 2.416zM175.31 168.704c0 1.318-1.102 2.416-2.424 2.416-1.322 0-2.424-1.098-2.424-2.416 0-1.317 1.102-2.415 2.424-2.415 1.322 0 2.424 1.098 2.424 2.415zM198.999 170.516c0 1.317-1.101 2.415-2.424 2.415-1.322 0-2.424-1.098-2.424-2.415 0-1.318 1.102-2.416 2.424-2.416a2.405 2.405 0 012.424 2.416z"
        fill="#FAFAFA"
      />
      <path
        d="M271.587 91.592c-3.163 4.745-3.386 8.238-2.317 10.297 1.782 3.448 5.079 2.955 7.217 6.089 2.005 2.91 1.471 5.641 1.07 8.193"
        stroke="#5384EE"
        strokeWidth={0.529}
        strokeMiterlimit={10}
      />
      <path
        d="M271.677 96.114h-.981c-.133-.045-.222-.09-.356-.135a4.698 4.698 0 01-2.406-1.388c-.401-.492-.401-.94.045-1.388.178-.179.356-.268.579-.403.134-.09.267-.134.401-.223-.045-.09-.045-.135-.045-.135-.133-.09-.267-.134-.445-.224-1.96-1.03-3.698-2.328-5.168-4.03-3.341-3.805-4.99-8.327-5.257-13.341-.267-4.836.713-9.447 2.94-13.79 2.183-4.298 5.257-7.79 9.445-10.208 3.297-1.925 6.861-2.865 10.692-2.552 4.055.313 7.574 1.88 10.514 4.746 2.406 2.328 4.055 5.149 5.124 8.283 1.381 4.03 1.648 8.148.935 12.312-.802 4.88-2.717 9.268-5.925 13.029-2.361 2.776-5.123 5.014-8.554 6.402-3.519 1.433-7.128 1.836-10.826.627-.089-.045-.178 0-.311 0 .133.269.267.537.356.761.356.806.178 1.298-.668 1.612.044-.045 0 0-.089.045z"
        fill="url(#celebrateBirthDay44_linear)"
      />
      <path
        d="M296.804 61.236c-1.07-3.134-2.718-5.955-5.124-8.283-2.94-2.82-6.46-4.388-10.558-4.701-.713-.045-1.381-.045-2.094-.045-1.693.985-3.163 2.194-4.544 3.627-2.852 3-4.634 6.536-5.48 10.566-.758 3.447-.758 6.895.223 10.298.757 2.641 2.004 5.059 3.876 7.118 2.316 2.508 5.167 3.985 8.553 4.433 3.163.403 6.193-.224 9-1.657a19.151 19.151 0 006.014-4.79c.446-1.344.757-2.776 1.025-4.209.712-4.209.49-8.328-.891-12.357z"
        fill="url(#celebrateBirthDay45_linear)"
      />
      <path
        d="M293.907 69.787c-.089-.94-.089-1.925-.223-2.865-.267-2.15-.98-4.164-2.004-6.044-.223-.448-.58-.851-.981-1.164-.49-.403-.98-.18-.98.492 0 .448 0 .94.089 1.388.491 3.403.357 6.805.045 10.208-.089.895-.178 1.79-.178 2.686 0 .448.089.896.267 1.299.49 1.12 1.827 1.298 2.673.403.223-.224.357-.537.49-.806.178-.403.268-.806.357-1.209"
        fill="#fff"
      />
      <path
        d="M283.794 91.278c-4.143.806-8.242.448-12.118-1.254-4.143-1.835-7.262-4.79-9.266-8.82-1.649-3.313-2.362-6.85-2.362-10.521 0-4.701 1.248-9.134 3.52-13.253.312-.582.668-1.164 1.025-1.746-1.426 1.612-2.584 3.403-3.609 5.373-2.183 4.343-3.208 8.954-2.94 13.79.267 5.014 1.915 9.536 5.257 13.387a18.13 18.13 0 005.168 4.029c.133.09.267.134.445.224.045 0 .045.045.045.134a1.785 1.785 0 00-.401.224c-.179.134-.401.224-.58.403-.445.448-.445.94-.044 1.388.624.761 1.47 1.164 2.406 1.388.133.045.222.09.356.134h.98c.089-.045.134-.09.223-.134.802-.314 1.025-.806.668-1.612-.133-.224-.223-.493-.356-.761.133 0 .223-.045.312 0 3.697 1.209 7.306.806 10.826-.627a20.552 20.552 0 005.078-3c-1.514.493-3.029.94-4.633 1.254z"
        fill="url(#celebrateBirthDay46_linear)"
      />
      <path
        d="M283.082 103.456c-1.069 3.671-3.163 4.164-4.366 6.402-1.247 2.329-1.024 3.985-1.113 5.776"
        stroke="#5384EE"
        strokeWidth={0.529}
        strokeMiterlimit={10}
      />
      <path
        d="M284.641 105.113c-.356-.09-.713-.224-1.024-.314-.089-.089-.223-.179-.312-.268-.936-.538-1.649-1.299-2.094-2.284-.267-.627-.089-1.164.49-1.477.223-.135.49-.179.757-.224.179-.045.357-.045.49-.09v-.179l-.401-.403c-1.782-1.746-3.207-3.716-4.232-6-2.317-5.193-2.539-10.566-1.203-16.028 1.292-5.283 3.921-9.85 7.663-13.745 3.742-3.85 8.197-6.536 13.499-7.745 4.188-.94 8.286-.762 12.251.85 4.233 1.702 7.485 4.567 9.668 8.552 1.782 3.268 2.584 6.805 2.718 10.521.178 4.746-.936 9.223-3.074 13.477-2.451 4.925-5.97 8.954-10.603 11.909-3.431 2.194-7.129 3.627-11.227 3.94-4.233.358-8.198-.448-11.762-2.955-.089-.045-.178-.045-.312-.089.045.358.089.626.134.94.134.985-.267 1.432-1.247 1.522.044 0-.045.045-.179.09z"
        fill="url(#celebrateBirthDay47_linear)"
      />
      <path
        d="M323 76.28c-.133-3.716-.935-7.253-2.717-10.521-2.183-4.03-5.436-6.85-9.668-8.552-.757-.313-1.47-.537-2.227-.761-2.094.448-4.099 1.254-6.015 2.328-4.009 2.239-7.083 5.462-9.356 9.447-1.96 3.403-3.074 7.074-3.163 11.059-.044 3.09.446 6.089 1.782 8.865 1.649 3.447 4.233 5.955 7.663 7.566 3.252 1.523 6.683 1.836 10.158 1.254 2.94-.493 5.613-1.567 8.019-3.09.936-1.298 1.737-2.686 2.495-4.163 2.094-4.164 3.163-8.641 3.029-13.432z"
        fill="url(#celebrateBirthDay48_linear)"
      />
      <path
        d="M317.074 84.428c.223-1.03.535-2.06.713-3.134.401-2.373.312-4.746-.178-7.12a5.395 5.395 0 00-.668-1.566c-.401-.582-.981-.493-1.203.179a9.506 9.506 0 00-.401 1.522c-.579 3.806-1.871 7.388-3.342 10.925-.401.94-.757 1.88-1.069 2.82-.134.448-.223.985-.178 1.478.178 1.388 1.515 2.014 2.718 1.298.311-.179.579-.448.801-.716.312-.358.535-.761.802-1.164"
        fill="#fff"
      />
      <path
        d="M299.21 103.993c-4.723-.537-8.955-2.238-12.519-5.417-3.787-3.358-6.148-7.522-6.994-12.536-.669-4.075-.223-8.104.98-12 1.559-5.014 4.366-9.312 8.108-12.939.535-.537 1.114-1.03 1.648-1.522a27.329 27.329 0 00-5.613 4.567c-3.742 3.85-6.371 8.417-7.663 13.7-1.336 5.463-1.114 10.835 1.203 16.029a19.012 19.012 0 004.232 6l.401.402v.179c-.178.045-.356.045-.49.09-.267.045-.534.089-.757.224-.624.313-.802.85-.49 1.477.445.985 1.158 1.746 2.094 2.284.133.089.222.179.312.268.356.09.712.224 1.024.314.089 0 .178-.045.268-.045.98-.045 1.381-.537 1.247-1.522-.045-.314-.089-.582-.134-.941.134.045.223.045.312.09 3.52 2.507 7.529 3.313 11.762 2.955a21.694 21.694 0 006.415-1.522c-1.782.134-3.52.089-5.346-.135z"
        fill="url(#celebrateBirthDay49_linear)"
      />
      <defs>
        <linearGradient
          id="celebrateBirthDay0_linear"
          x1={149.916}
          y1={98.387}
          x2={149.916}
          y2={221.199}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DCE9FC" />
          <stop offset={1} stopColor="#DCE9FC" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="celebrateBirthDay1_linear"
          x1={302.866}
          y1={173.132}
          x2={304.331}
          y2={187.582}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="celebrateBirthDay2_linear"
          x1={318.349}
          y1={185.801}
          x2={318.349}
          y2={228.85}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset={1} stopColor="#CCE1F7" />
        </linearGradient>
        <linearGradient
          id="celebrateBirthDay3_linear"
          x1={276.27}
          y1={222.623}
          x2={313.825}
          y2={221.545}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset={1} stopColor="#CCE1F7" />
        </linearGradient>
        <linearGradient
          id="celebrateBirthDay4_linear"
          x1={240.743}
          y1={196.48}
          x2={343.835}
          y2={166.068}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#92ADC9" />
          <stop offset={1} stopColor="#B3CCE6" />
        </linearGradient>
        <linearGradient
          id="celebrateBirthDay5_linear"
          x1={318.3}
          y1={179.209}
          x2={318.3}
          y2={195.09}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset={1} stopColor="#CCE1F7" />
        </linearGradient>
        <linearGradient
          id="celebrateBirthDay6_linear"
          x1={272.899}
          y1={192.793}
          x2={313.899}
          y2={189.289}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset={1} stopColor="#CCE1F7" />
        </linearGradient>
        <linearGradient
          id="celebrateBirthDay7_linear"
          x1={322.786}
          y1={188.942}
          x2={343.922}
          y2={200.764}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="celebrateBirthDay8_linear"
          x1={281.5}
          y1={196.488}
          x2={281.5}
          y2={237.539}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset={1} stopColor="#CCE1F7" />
        </linearGradient>
        <linearGradient
          id="celebrateBirthDay9_linear"
          x1={239.517}
          y1={231.601}
          x2={278.227}
          y2={230.401}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset={1} stopColor="#CCE1F7" />
        </linearGradient>
        <linearGradient
          id="celebrateBirthDay10_linear"
          x1={205.983}
          y1={205.886}
          x2={304.543}
          y2={173.736}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#92ADC9" />
          <stop offset={1} stopColor="#B3CCE6" />
        </linearGradient>
        <linearGradient
          id="celebrateBirthDay11_linear"
          x1={281.2}
          y1={190.595}
          x2={281.2}
          y2={205.178}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset={1} stopColor="#CCE1F7" />
        </linearGradient>
        <linearGradient
          id="celebrateBirthDay12_linear"
          x1={235.936}
          y1={203.068}
          x2={278.235}
          y2={198.998}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset={1} stopColor="#CCE1F7" />
        </linearGradient>
        <linearGradient
          id="celebrateBirthDay13_linear"
          x1={287.438}
          y1={199.665}
          x2={305.871}
          y2={209.032}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="celebrateBirthDay14_linear"
          x1={213.135}
          y1={174.282}
          x2={214.85}
          y2={190.431}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="celebrateBirthDay15_linear"
          x1={236.95}
          y1={189.297}
          x2={236.95}
          y2={250.124}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset={1} stopColor="#CCE1F7" />
        </linearGradient>
        <linearGradient
          id="celebrateBirthDay16_linear"
          x1={222.115}
          y1={241.326}
          x2={271.731}
          y2={239.995}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset={1} stopColor="#CCE1F7" />
        </linearGradient>
        <linearGradient
          id="celebrateBirthDay17_linear"
          x1={139.742}
          y1={203.564}
          x2={270.137}
          y2={167.5}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#92ADC9" />
          <stop offset={1} stopColor="#B3CCE6" />
        </linearGradient>
        <linearGradient
          id="celebrateBirthDay18_linear"
          x1={237.25}
          y1={180.907}
          x2={237.25}
          y2={201.682}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset={1} stopColor="#CCE1F7" />
        </linearGradient>
        <linearGradient
          id="celebrateBirthDay19_linear"
          x1={219.692}
          y1={198.421}
          x2={273.801}
          y2={193.685}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset={1} stopColor="#CCE1F7" />
        </linearGradient>
        <linearGradient
          id="celebrateBirthDay20_linear"
          x1={221.556}
          y1={194.854}
          x2={246.847}
          y2={206.47}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="celebrateBirthDay21_linear"
          x1={248.055}
          y1={184.238}
          x2={257.731}
          y2={205.672}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="celebrateBirthDay22_linear"
          x1={201.084}
          y1={188.334}
          x2={206.114}
          y2={207.905}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="celebrateBirthDay23_linear"
          x1={27}
          y1={173.059}
          x2={27}
          y2={226.788}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset={1} stopColor="#CCE1F7" />
        </linearGradient>
        <linearGradient
          id="celebrateBirthDay24_linear"
          x1={13.857}
          y1={219.108}
          x2={56.056}
          y2={218.02}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset={1} stopColor="#CCE1F7" />
        </linearGradient>
        <linearGradient
          id="celebrateBirthDay25_linear"
          x1={-54.402}
          y1={187.032}
          x2={56.806}
          y2={160.792}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#92ADC9" />
          <stop offset={1} stopColor="#B3CCE6" />
        </linearGradient>
        <linearGradient
          id="celebrateBirthDay26_linear"
          x1={26.9}
          y1={165.069}
          x2={26.9}
          y2={185.509}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset={1} stopColor="#CCE1F7" />
        </linearGradient>
        <linearGradient
          id="celebrateBirthDay27_linear"
          x1={11.454}
          y1={181.017}
          x2={57.777}
          y2={177.21}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset={1} stopColor="#CCE1F7" />
        </linearGradient>
        <linearGradient
          id="celebrateBirthDay28_linear"
          x1={13.57}
          y1={177.64}
          x2={40.852}
          y2={194.844}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="celebrateBirthDay29_linear"
          x1={28.965}
          y1={178.458}
          x2={30.268}
          y2={191.591}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="celebrateBirthDay30_linear"
          x1={51.85}
          y1={190.838}
          x2={51.85}
          y2={235.085}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset={1} stopColor="#CCE1F7" />
        </linearGradient>
        <linearGradient
          id="celebrateBirthDay31_linear"
          x1={42.082}
          y1={228.685}
          x2={79.407}
          y2={227.649}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset={1} stopColor="#CCE1F7" />
        </linearGradient>
        <linearGradient
          id="celebrateBirthDay32_linear"
          x1={-25.756}
          y1={201.859}
          x2={77.843}
          y2={172.246}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#92ADC9" />
          <stop offset={1} stopColor="#B3CCE6" />
        </linearGradient>
        <linearGradient
          id="celebrateBirthDay33_linear"
          x1={51.9}
          y1={184.146}
          x2={51.9}
          y2={200.327}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset={1} stopColor="#CCE1F7" />
        </linearGradient>
        <linearGradient
          id="celebrateBirthDay34_linear"
          x1={40.1}
          y1={197.986}
          x2={81.11}
          y2={194.546}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset={1} stopColor="#CCE1F7" />
        </linearGradient>
        <linearGradient
          id="celebrateBirthDay35_linear"
          x1={40.087}
          y1={194.147}
          x2={61.457}
          y2={205.822}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="celebrateBirthDay36_linear"
          x1={143.196}
          y1={97.995}
          x2={147.989}
          y2={97.995}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="celebrateBirthDay37_linear"
          x1={145.561}
          y1={68.183}
          x2={145.561}
          y2={86.465}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6D8CC2" />
          <stop offset={1} stopColor="#98C0E8" />
        </linearGradient>
        <linearGradient
          id="celebrateBirthDay38_linear"
          x1={117.835}
          y1={125.703}
          x2={176.408}
          y2={125.703}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="celebrateBirthDay39_linear"
          x1={147.076}
          y1={109.592}
          x2={147.076}
          y2={130.935}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6D8CC2" />
          <stop offset={1} stopColor="#98C0E8" />
        </linearGradient>
        <linearGradient
          id="celebrateBirthDay40_linear"
          x1={106.832}
          y1={155.19}
          x2={187.445}
          y2={155.19}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="celebrateBirthDay41_linear"
          x1={147.076}
          y1={136.26}
          x2={147.076}
          y2={158.931}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6D8CC2" />
          <stop offset={1} stopColor="#98C0E8" />
        </linearGradient>
        <linearGradient
          id="celebrateBirthDay42_linear"
          x1={100.838}
          y1={192.806}
          x2={197.32}
          y2={192.806}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="celebrateBirthDay43_linear"
          x1={149.004}
          y1={162.555}
          x2={149.004}
          y2={199.188}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6D8CC2" />
          <stop offset={1} stopColor="#98C0E8" />
        </linearGradient>
        <linearGradient
          id="celebrateBirthDay44_linear"
          x1={213.647}
          y1={89.189}
          x2={306.252}
          y2={83.277}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset={1} stopColor="#CCE1F7" />
        </linearGradient>
        <linearGradient
          id="celebrateBirthDay45_linear"
          x1={235.668}
          y1={79.138}
          x2={304.146}
          y2={74.86}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D7E7F7" />
          <stop offset={1} stopColor="#CCE1F7" />
        </linearGradient>
        <linearGradient
          id="celebrateBirthDay46_linear"
          x1={224.329}
          y1={90.304}
          x2={294.689}
          y2={86.27}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D7E7F7" />
          <stop offset={1} stopColor="#CCE1F7" />
        </linearGradient>
        <linearGradient
          id="celebrateBirthDay47_linear"
          x1={224.674}
          y1={98.468}
          x2={332.335}
          y2={90.795}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset={1} stopColor="#CCE1F7" />
        </linearGradient>
        <linearGradient
          id="celebrateBirthDay48_linear"
          x1={253.152}
          y1={91.338}
          x2={329.793}
          y2={86.587}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D7E7F7" />
          <stop offset={1} stopColor="#CCE1F7" />
        </linearGradient>
        <linearGradient
          id="celebrateBirthDay49_linear"
          x1={245.103}
          y1={99.055}
          x2={310.436}
          y2={96.009}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D7E7F7" />
          <stop offset={1} stopColor="#CCE1F7" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SvgCelebrateBirthday;
